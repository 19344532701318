

























import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { ButtonProps } from './Button.contract'
import { buttonProps, useButton } from './Button.hooks'
import LoadingIcon from './partials/LoadingIcon.vue'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const Button = defineComponent({
  name: 'Button',
  components: { LoadingIcon },
  props: buttonProps,
  emits: ['click'],
  setup (props: ButtonProps) {
    return useButton(props, getCurrentInstance())
  }
})
export default Button
