











import { defineComponent, SetupContext } from '@vue/composition-api'

import { CustomLink } from '../../../atoms/Link'
import { CustomIcon } from '../../../atoms/Icon'

import { DropdownItemProps } from './DropdownItem.contracts'
import { dropdownItemProps, useDropdownItem } from './DropdownItem.hooks'

/**
 *
 * Component capable to render Dropdown Item element.
 *
 * @author Javlon Khalimjonov <javloon.khalimjonov@movecloser.pl>
 */
export const DropdownItem = defineComponent({
  name: 'DropdownItem',
  components: { CustomIcon, CustomLink },
  props: dropdownItemProps,

  setup (props: DropdownItemProps, ctx: SetupContext) {
    return useDropdownItem(props, ctx.emit)
  }
})

export default DropdownItem
