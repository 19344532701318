






































import { defineComponent } from '@vue/composition-api'
import { VPopover } from 'v-tooltip'

import { CustomButton } from '../../atoms/Button'

import { DropdownItem } from './DropdownItem'
import { DropdownProps } from './Dropdown.contracts'
import { dropdownProps, useDropdown } from './Dropdown.hooks'

/**
 * Component capable to render `Dropdown` element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Dropdown = defineComponent({
  name: 'Dropdown',
  components: {
    CustomButton,
    DropdownItem,
    UiTooltip: VPopover
  },
  props: dropdownProps,

  setup (props: DropdownProps, { slots }) {
    return useDropdown(props, slots)
  }
})
export default Dropdown
