












import { defineComponent } from '@vue/composition-api'

import { customIconProps, useCustomIcon } from './Icon.hooks'
import { IconProps } from './Icon.contracts'

/**
 * Component capable to render Icon component.
 *
 * @displayName Icon
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Icon = defineComponent({
  name: 'CustomIcon',
  props: customIconProps,

  setup (props: IconProps) {
    return useCustomIcon(props)
  }
})
export default Icon
